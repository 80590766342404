.App {
  text-align: center;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width:200px;
  height: 45px;
  left: 20px;
  top:20px;
  margin-top:10px;
}

@media (max-width: 767px) {
  .logo {
    width: 200px; /* スマホでのロゴサイズ */
    margin-left:auto;
    margin-right:auto;
    position:relative;
    display:block;
    object-fit:contain;
  }
}


.footer {
  background-color:#23A6BA ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}

.footer-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-link {
  margin: 0 10px;
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-link:visited {
  color: white;
}

.small-text {
  font-size: 12px;
}

.copyright {
  font-size: 10px;
  margin-right: 10px;
}

.content {
  padding-bottom: 60px; /* フッターの高さと同じかそれ以上の値 */
}

.title 
{
  font-size: 18px;
  color:#23A6BA;
  margin-right: 20px;
  position: relative;
  top: 15px; /* ここで下方向にオフセットする量を調整してください */
  font-family: 'Courgette', cursive;
}

@media (max-width: 767px) {
  .title {
  font-size: 18px;
  margin-right: 20px;
  position: relative;
  top: 25px; /* ここで下方向にオフセットする量を調整してください */
  }
}

.footer-link:hover {
  text-decoration: underline;
}
